function enumValue(value, label, validate ) {
    return Object.freeze({ value: Object.freeze(value), label, validate });
};

const YES_RELEASED = enumValue(
    {
        avoidedInquiry: true,
        releasedClaim: true,
        refusedClaim: false
    }, 'Sim, com Sinistro Liberado.',
    (avoidedInquiry, releasedClaim, refusedClaim) => avoidedInquiry && releasedClaim && !refusedClaim
);
const YES_REFUSED = enumValue(
    {
        avoidedInquiry: true,
        releasedClaim: false,
        refusedClaim: true
    },
    'Sim, com Sinistro Recusado sem Sindicância.',
    (avoidedInquiry, releasedClaim, refusedClaim) => avoidedInquiry && !releasedClaim && refusedClaim
);
const NO = enumValue(
    {
        avoidedInquiry: false,
        releasedClaim: false,
        refusedClaim: false
    }, 'Não',
    (avoidedInquiry, releasedClaim, refusedClaim) => !avoidedInquiry && !releasedClaim && !refusedClaim)
;

export function values() {
    return [YES_RELEASED, YES_REFUSED, NO];
}