import Api, { get } from "./Api";

class VehicleApi extends Api {
  constructor() {
    super(process.env.REACT_APP_VEHICLE_ENDPOINT);
  }

  getV3(plate){
    return get(`${process.env.REACT_APP_VEHICLE_V3_ENDPOINT}/${plate}`, { timeout: 15000 })
  }
}

export default VehicleApi