import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { isNumber } from "lodash";

const TOKEN_KEY = "TOKEN";

export const formatDate = (date) => {
  if (!!date && !isNaN(date)) {
    const withOffset = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const strDate = withOffset.toISOString().replace("T", " ");
    return strDate.substr(0, 10);
  }
  return "";
};

export const formatDateGMT = (date) => {
  if (!!date && !isNaN(date)) {
    const withOffset = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return withOffset.toISOString();
  }
  return "";
};

export const formatDateDayMonth = (date) =>
new Date(date + " ").toLocaleString().split(",")[0];

const LETTERS = Array.from({ length: 10 }, (_, i) =>
  String.fromCharCode(i + 65)
);

export const formatPlate = (plate) => plate && isNumber(+plate[4])? formatPlateNewPatten(plate): formatPlateToOldPattern(plate);

export const formatPlateNewPatten = (plate) => {
  if (!plate || plate.length < 7) {
    return plate || ""
  }
  const letter = plate[4];
  const index = LETTERS.indexOf(letter);
  return (
    plate.slice(0, 4) + (index > -1 ? index : LETTERS[letter]) + plate.slice(5)
  );
}

export const formatPlateToOldPattern = (plate) => {
  if (!plate || plate.length < 7) {
    return plate || ""
  }
  else if (isNumber(+plate.substring(5, 6))) {
    return plate;
  }
  let formattedPlate = [plate.slice(0, 4), LETTERS.indexOf(plate.charAt(4)), plate.slice(5)].join("");
  return formattedPlate
}

export const truncateText = (text, maxNum) => {
  const textLength = text.length;
  return textLength > maxNum ? `${text.slice(0, maxNum)}...` : text;
};

export function calcWidth() {
  return (window.outerWidth / 100) * ((100 / 12) * 10);
}

export function getCurrentUser() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!!token) {
    return jwtDecode(token);
  }
  return {};
}

export function isAllow(roles) {
  const user = getCurrentUser();
  return roles.length === 0 || roles.some(role => user?.Role?.includes(role.toString()));
}

export function isPartialPlateValid(plate) {
  let isValid = plate.length < 8;
  if (plate !== "") {
    isValid = isValid && plate.search(/\D{1,3}/) === 0;
    if (plate.length > 3) {
      isValid = isValid && plate.search(/\d/) === 3;
      if (plate.length > 4) {
        isValid =
          isValid &&
          plate
            .charAt(4)
            .toUpperCase()
            .search(/\d|[A-J]/) === 0;
        if (plate.length > 5) {
          isValid = isValid && plate.substring(5).search(/\d{1,2}/) === 0;
        }
      }
    }
  }
  return isValid;
}

export function isPlateValid(plate) {
  let isValid = plate.length === 7;
  if (plate !== "") {
    isValid = isValid && plate.search(/\w{1,3}/) === 0;
    if (plate.length > 3) {
      isValid = isValid && plate.search(/\d{1,2}/) === 3;
      if (plate.length > 5) {
        isValid =
          isValid &&
          plate
            .charAt(5)
            .toUpperCase()
            .search(/\d|[A-J]/) === 0;
        if (plate.length > 6) {
          isValid = isValid && plate.charAt(6).search(/\d/) === 0;
        }
      }
    }
  }
  return isValid;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function objectToQueryString(obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function zeroPad(num, length) {
  return String(num).padStart(length, "0");
}

export function phoneValidate(phone) {
  phone = phone.replace(/\D/g, '');
  if (!(phone.length >= 10 && phone.length <= 11)) {
    return false;
  }
  //Se tiver 11 caracteres, verificar se começa com 9 o celular
  if (phone.length === 11 && parseInt(phone.substring(2, 3)) !== 9) {
    return false;
  }
  //verifica se o numero foi digitado com todos os dígitos iguais
  const find = phone.match(`${phone.charAt(0)}{${phone.length}}`)
  if(find){
    return false;
  }

  const ddd = [
    11, 12, 13, 14, 15, 16, 17, 18, 19,
    21, 22, 24, 27, 28,
    31, 32, 33, 34, 35, 37, 38, 
    41, 42, 43, 44, 45, 46, 47, 48, 49,
    51, 53, 54, 55,
    61, 62, 64, 63, 65, 66, 67, 68, 69,
    71, 73, 74, 75, 77, 79,
    81, 82, 83, 84, 85, 86, 87, 88, 89,
    91, 92, 93, 94, 95, 96, 97, 98, 99
  ]

  if (!ddd.includes(parseInt(phone.substring(0, 2)))){
    return false;
  }
  //Se o número só tiver dez digitos não é um celular e por isso o número logo após o DDD deve ser 2, 3, 4, 5 ou 7
  const prefix = [2, 3, 4, 5, 7];
  return !(phone.length9  === 10 && !prefix.includes(parseInt(phone.substring(2, 3))))
}

export function array_move(arr, old_index, new_index) {
  const newArray = [...arr]
  if (new_index >= newArray.length) {
      let k = new_index - newArray.length + 1;
      while (k--) {
          newArray.push(undefined);
      }
  }
  newArray.splice(new_index, 0, newArray.splice(old_index, 1)[0]);
  return newArray; // for testing
};

export function cropCar(source, coordinates) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      const coord = coordinates
        .split(";")
        .map(coordinate => coordinate.split(",").map(c => parseInt(c)));
      let xs = [coord[0][0], coord[1][0], coord[2][0], coord[3][0]];
      let ys = [coord[0][1], coord[1][1], coord[2][1], coord[3][1]];
      let minX = Math.min(...xs);
      let minY = Math.min(...ys);
      let maxX = Math.max(...xs);
      let maxY = Math.max(...ys);
      const height = Math.abs(maxY - minY);
      const width = Math.abs(maxX - minX);
      const pixelCrop = {
        x: Math.trunc(parseInt(coord[0][0]) - width * 2),
        y: Math.trunc(minY - height * 8),
        height: height * 12,
        width: width * 5,
      };
      const canvas = document.createElement("canvas");
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext("2d");
      try {
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
      } catch (e) {
        console.error(e);
        reject(e)
        return;
      }
  
      canvas.toBlob((blob) => {
        setTimeout(() => canvas.remove(), 100);
        if (!blob) {
          reject("Canvas is empty");
        } else {
          blob.name = `${new Date().getTime()}.jpg`;
          resolve(window.URL.createObjectURL(blob));
        }
      }, "image/jpeg");
    }
    img.src = source;
  })
}

export function drawBBoxPlate(source, coordinates) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      let coord = coordinates
        .split(";")
        .map((coordinate) => coordinate.split(",").map(c => parseInt(c)));
      const marginWidth = 0.005 * img.width;
      const marginHeight = 0.005 * img.height;
      let xs = [parseInt(coord[0][0]) - marginWidth, parseInt(coord[1][0]) + marginWidth, parseInt(coord[2][0]) + marginWidth, parseInt(coord[3][0]) - marginWidth];
      let ys = [parseInt(coord[0][1]) - marginHeight, parseInt(coord[1][1]) - marginHeight, parseInt(coord[2][1]) + marginHeight, parseInt(coord[3][1]) + marginHeight];
      const canvas = document.createElement("canvas");
      canvas.height = img.height;
      canvas.width = img.width;
      const ctx = canvas.getContext("2d");
      try {
        ctx.drawImage(img, 0, 0);
        ctx.strokeStyle = "lawngreen";
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(xs[0], ys[0]);
        ctx.lineTo(xs[1], ys[1]);
        ctx.lineTo(xs[2], ys[2]);
        ctx.lineTo(xs[3], ys[3]);
        ctx.lineTo(xs[0], ys[0]);
        ctx.stroke();
      } catch (e) {
        reject(e);
        return;
      }
      img.remove();
      canvas.toBlob((blob) => {
        setTimeout(() => canvas.remove(), 100);
        if (!blob) {
          reject("Canvas is empty");
        } else {
          blob.name = `${new Date().getTime()}.jpg`;
          resolve(window.URL.createObjectURL(blob));
        }
      }, "image/jpeg");
    };
    img.src = source;
  });
}

export function importPreviewCSV(chunkSize, callback, fallback=()=>{}) {
  return ([file]) => {
    const fr = new FileReader();
    const CHUNK_SIZE = chunkSize;
    fr.onload = function ({ target: { result } }) {
        let buffer = new Uint8Array(result);
        let text = new TextDecoder('windows-1252').decode(buffer);
        let results = [];
        const lines = text.split(/\r?\n/);
        const titles = lines[0].replace('ï»¿', '').split(';');
        const lastLine = lines[lines.length - 1].split(';');
        if (titles.length !== lastLine.length) {
          lines.pop();
        }
        const len = lines.length > 21? 21: lines.length;
        for (let index = 1; index < len; index++) {
            if (!lines[index]){
                break;
            }
            const line = lines[index].split(';');
            if(line.length !== titles.length){
              fallback(`A linha ${index} e o cabeçalho tem tamanhos incompativeis`);
              break;
            }
            const objLine = titles.map((element, index2) => [element, line[index2]])
              .reduce((acc, curr) => {
                  acc[curr[0].toUpperCase()] = curr[1];
                  return acc;
              }, {});
            results = [ ...results, objLine];
        }
        callback(results, file)
    }
    const end = file.size > CHUNK_SIZE? CHUNK_SIZE: file.size;
    const slice = file.slice(0, end);
    fr.readAsArrayBuffer(slice);
  }
}

export const ufs = [
  "AC","AL","AP","AM","BA","CE","DF","ES","GO","MA","MT","MS","MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO"
]

export function removeAccents(text){       
    text = text.toUpperCase();                                                         
    text = text.replace(/[ÁÀÂÃ]/gi, 'A');
    text = text.replace(/[ÉÈÊ]/gi, 'E');
    text = text.replace(/[ÍÌÎ]/gi, 'I');
    text = text.replace(/[ÓÒÔÕ]/gi, 'O');
    text = text.replace(/[ÚÙÛ]/gi, 'U');
    text = text.replace(/[Ç]/gi, 'C');
    return text;                 
}

export const copy = (text, message="Copiado com sucesso!") => {
  navigator.clipboard.writeText(text); 
  toast.success(message, {
      hideProgressBar: true,
      autoClose: 3000,
  });
}