import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Portal from "../Portal";

export default ({ isOpen, toggle, msg, success=false }) => (
  <Portal>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}> {success ? "Sucesso!": "Erro!"}</ModalHeader>
      <ModalBody>{msg}</ModalBody>
      <ModalFooter>
        <Button color={success ? "primary": "secondary"} onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  </Portal>
);
