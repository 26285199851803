import React from "react";
import InputMask from "react-input-mask";
import { Input } from "reactstrap";

export default ({ id, value, onChange }) => (
    <InputMask
        id={id}
        mask="aaa9*99"
        value={value}
        alwaysShowMask={false}
        maskChar=""
        placeholder="Placa"
        onChange={({ target: { value } }) => onChange(value.toUpperCase())}>
        { inputProps => <Input {...inputProps}/> }
    </InputMask>
)