import {
  ActivatePage,
  CameraPages,
  CompaniesPage,
  CustomersPages,
  DashboardPage,
  DossierPages,
  FeedbackPages,
  ForgotPage,
  HitPage,
  TrackerPages,
  IntegratedPage,
  LoginPage,
  QualiApiPage,
  QualityPage,
  TargetListPage,
  UserPages,
  UploaderPage,
  TrackingPage,
  CataplacaPage,
  SolicitationPage,
  ProviderPages,
  ClonedPages
} from "./pages";
import { RouteInfo } from "./routing";
import { Roles } from "./model";

export const publicRoutes = [
  new RouteInfo("/login", LoginPage),
  new RouteInfo("/forgot", ForgotPage),
  new RouteInfo("/ativar", ActivatePage),
  new RouteInfo(),
];

export const privateRoutes = [
  new RouteInfo("/search/:page?", HitPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_ANALYST,
    Roles.ROLE_SEARCH,
    Roles.ROLE_RESEARCHER_CAMERA,
    Roles.ROLE_INSURANCE_ANALYST,
    Roles.ROLE_INSURANCE_MANAGER,
    Roles.ROLE_HOTLIST_MANAGER,
  ]),
  new RouteInfo("/clientes", CustomersPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_CUSTOMER_MANAGER,
  ]),
  new RouteInfo("/targetList", TargetListPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_ANALYST,
  ]),
  new RouteInfo("/uploader", UploaderPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_QUALITY,
    Roles.ROLE_INSURANCE_MANAGER
  ]),
  new RouteInfo("/qualidade", QualityPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_QUALITY,
  ]),
  new RouteInfo("/dossie", DossierPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_ANALYST,
  ]),
  new RouteInfo("/usuarios", UserPages, [Roles.ROLE_ADMIN]),
  new RouteInfo("/empresas", CompaniesPage, [Roles.ROLE_ADMIN]),
  new RouteInfo("/cameras", CameraPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_CAMERA_ADMIN
  ]),
  new RouteInfo("/avaliacoes", FeedbackPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_INSURANCE_ANALYST,
    Roles.ROLE_INSURANCE_MANAGER,
    Roles.ROLE_HOTLIST_MANAGER,
  ]),
  new RouteInfo("/dashboard", DashboardPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_INSURANCE_MANAGER,
  ]),
  new RouteInfo("/tracker", TrackerPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_HOTLIST_MANAGER,
    Roles.ROLE_QUALITY_HOTLIST,
    Roles.ROLE_HOTLIST_VIEWER,
    Roles.ROLE_QUALITY
  ]),

  new RouteInfo("/integrada", IntegratedPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_HOTLIST_MANAGER,
    Roles.ROLE_HOTLIST_VIEWER
  ]),

  new RouteInfo("/quali-api", QualiApiPage, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_INSURANCE_MANAGER,
    Roles.ROLE_INSURANCE_ANALYST,
    Roles.ROLE_QUALITY
  ]),
  new RouteInfo("/tracking", TrackingPage,[
    Roles.ROLE_ADMIN,
    Roles.ROLE_INSURANCE_CARTRACKING,
  ]),
  new RouteInfo("/solicitacoes", SolicitationPage, [Roles.ROLE_ADMIN]),
  new RouteInfo("/fornecedores", ProviderPages, [
    Roles.ROLE_ADMIN,
    Roles.ROLE_CAMERA_ADMIN,
  ]),
  new RouteInfo("/cataplaca", CataplacaPage, [
    Roles.ROLE_PLATE_HUNTER
  ]),
  new RouteInfo("/duble", ClonedPages, [Roles.ROLE_ADMIN])
];
